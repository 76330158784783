@import '~rfs/scss';

$color-primary: #D9FFDF;
$color-background: #F7F7F7;
$color-text: #212121;
$color-gray: #595959;
$color-graydark: #2c2c2c;

$font-family-serif: orpheuspro, serif;
$font-family-sansserif: neue-haas-grotesk-text, sans-serif;

$font-size-root: 18px; // (also) font-size-body

$font-size-big: 24vw; // (special) used in #header
$font-size-heading: calc(6vw + 5vh);
$font-size-subheading: 4rem;
$font-size-subheading2: 4rem;
$font-size-body: 1rem;
$font-size-small: 0.8rem;

// Font weight
$font-weight-bold: 700;
$font-weight-heading: 400;
$font-weight-subheading: 400;
$font-weight-subheading2: 700;
$font-weight-body: 400;
$font-weight-small: 400;

// Font letter spacing
$font-spacing-heading: 0;
$font-spacing-subheading: 0;
$font-spacing-subheading2: 0;
$font-spacing-body: 0;
$font-spacing-small: 5px;

$margin-section: 25rem;
$margin-heading: 0.8em;

$space: 1em;

@mixin font-body($weight: $font-weight-body, $size: $font-size-body, $spacing: $font-spacing-body) {
    @include font-size($size);
    font-style: normal;
    font-weight: $weight;
    letter-spacing: $spacing;
    line-height: 1.2;
}

@mixin font-heading($weight: $font-weight-heading, $size: $font-size-heading, $spacing: $font-spacing-heading) {
    @include font-size($size);
    font-family: $font-family-serif;
    font-weight: $weight;
    letter-spacing: $spacing;
    line-height: 1;
    text-transform: uppercase;
}

@mixin font-subheading($weight: $font-weight-subheading, $size: $font-size-subheading, $spacing: $font-spacing-subheading) {
    @include font-size($size);
    font-weight: $weight;
    letter-spacing: $spacing;
    line-height: 1;
}

@mixin font-subheading2($weight: $font-weight-subheading2, $size: $font-size-subheading2, $spacing: $font-spacing-subheading2) {
    @include font-size($size);
    font-family: $font-family-sansserif;
    font-weight: $weight;
    letter-spacing: $spacing;
    line-height: 1;
}

@mixin font-small($weight: $font-weight-small, $size: $font-size-small, $spacing: $font-spacing-small) {
    @include font-size($size);
    font-weight: $weight;
    letter-spacing: $spacing;
    text-transform: uppercase;
}

@mixin margin-horizontal($size: $space) {
    @include margin-left($size);
    @include margin-right($size);
}

@mixin margin-vertical($size: $space) {
    @include margin-top($size);
    @include margin-bottom($size);
}

@mixin padding-horizontal($size: $space) {
    @include padding-left($size);
    @include padding-right($size);
}

@mixin padding-vertical($size: $space) {
    @include padding-top($size);
    @include padding-bottom($size);
}

@mixin flex-center($direction: row) {
    display: flex;
    flex-direction: $direction;
    justify-content: center;
    align-items: center;
}

%reset-space {
    margin: 0;
    padding: 0;
}

%border-heading {
    position: relative;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: -30px;
        width: 75px;
        border-top: 4px solid $color-text;
    }
}

// Other
$transition: all 0.2s ease-in-out;
$transition3s: all 0.3s ease;
// $borderradius: 0.3125rem;
$box-shadow: 0 2px 20px 2px rgba(0, 0, 0, 0.1);
$box-shadow-hover: 0 1rem 2.5rem rgba(22, 28, 45, .1),
0 .5rem 1rem -.75rem rgba(22, 28, 45, .1);

%box-shadow {
    transition: all 0.2s ease-in-out !important;

    &:hover {
        transform: translateY(-3px) !important;
        box-shadow: 0 1rem 2.5rem rgba(22, 28, 45, .1), 0 .5rem 1rem -.75rem rgba(22, 28, 45, .1) !important;
    }
}