@import '~rfs/scss';

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    $font-size-root: 14px;

    :root {
        font-size: $font-size-root;
    }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {}

@media (min-width: 991.98px) {
    .container {
        max-width: 90%;
    }

    #intro {

        .intro {

            &-image {

                &-txt {

                    &-circular {
                        width: 180px;
                        position: relative;
                        right: inherit;
                        bottom: inherit;
                    }
                }
            }
        }
    }


    #product {
        .product {
    
            &-item {

                &-more {
    
                    &-arrow {
                        max-width: 50px;
                    }
                }
            }
        }
    }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {}

@media (min-width: 1199.98px) {}

// Desktop size
@media (min-width: 1399.98px) {}