// Variables
@import 'normalize.css/normalize.css';
@import 'bootstrap/dist/css/bootstrap-grid.min.css';
@import 'locomotive-scroll/dist/locomotive-scroll.min.css';
@import 'swiper/swiper-bundle.min.css';
@import '~rfs/scss';
@import 'variables';
@import 'global';

#header {
    pointer-events: none;
    z-index: 9999;
    position: relative;

    .header {

        &-wrap {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100vh;
        }

        &-title {
            font-size: $font-size-big;
            line-height: 1;
            transform-origin: top;

            .char {
                transform: translateY(100%);
            }
        }

        &-desc,
        &-desc2 {
            font-family: $font-family-serif;
            @include font-body($size: $font-size-body + 0.5rem);

            .char {
                transform: translateY(100%);
            }
        }

        &-desc {
            margin-bottom: 0.3rem;
        }
    }
}

#intro {

    .intro {

        &-image {

            &-img {
                width: 100%;
            }

            &-txt {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-end;

                &-circular {
                    width: 80px;
                    position: absolute;
                    right: 20px;
                    bottom: 115px;
                }
            }
        }

        &-wrap {
            padding: 6rem 0;
        }

        &-title.right {
            text-align: right;
        }

        &-subtitle {
            margin-bottom: 1.5rem;
            display: block;
            text-align: right;
        }
    }
}

.swiper-container {
    width: 100%;
    height: 100%;
    position: relative;
    background: url("../../public/slider-bg.jpg") no-repeat;
    background-size: cover;
}

.swiper-slide {
    text-align: center;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    transform: scale(0.85);
    -webkit-transform:scale(0.85);

    img {
        border-radius: 30px;
    }
}

.slider-button {
    background: $color-background;
    color: $color-text;
    padding: 1rem;
    border-radius: 100%;
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.slider-button-next {
    right: 10px;

    img {
        transform: rotate(180deg);
    }
}

.slider-button-prev {
    left: 10px;
}

#product {
    .product {

        &-title {
            &-wrap {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                margin-bottom: 3rem;
            }
        }

        &-subtitle {
            line-height: 1.8;
        }

        &-item {
            padding: 3rem 0;
            border-top: 1px solid $color-text;
            border-bottom: 1px solid $color-text;

            &-text {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            &-desc {
                margin-top: 1rem;
            }

            &-more {
                position: relative;

                &-link {
                    @include font-subheading();
                    font-family: $font-family-serif;
                    text-transform: uppercase;
                    line-height: 0.8;

                    &::before,
                    ::after {
                        top: 80%;
                    }
                }

                &-arrow {
                    position: relative;
                    bottom: 20px;
                    left: 10px;
                    max-width: 30px;
                }

            }
        }
    }
}

#end {
    // padding-bottom: 40vh;

    .end {

        &-inline {
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
}

#footer {
    padding-bottom: 4rem;

    .footer {

        &-list {
            list-style: none;
            margin: 0;
            text-align: center;

            &-item {
                display: block;
                margin-bottom: 5px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.video {
    height: 100vh;
    width: 100%;
    background: $color-text;
    margin: 0;
    opacity: 0;
    transform: scale(0.5);

    &-player {
        width: 100%;
        height: 100%;
    }
}

@import 'responsive';